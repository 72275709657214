<template>
  <nav>
    <router-link to="/">Vue Option API</router-link> |
    <router-link to="/composition">Vue Composition API</router-link>
  </nav>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #e86f2d;
}

.poscredit-btn {
  display: block;
  max-width: 290px;
  background: #e86f2d;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 90px;
  padding: 16px 25px;
  cursor: pointer;
  outline: none;
  width: 100%;
}
</style>
