import { createRouter, createWebHistory } from 'vue-router';
import OptionsPage from '@/views/OptionsPage.vue';
import CompositionPage from '@/views/CompositionPage.vue';

const routes = [
  {
    path: '/',
    name: 'index',
    component: OptionsPage
  },
  {
    path: '/composition',
    name: 'composition',
    component: CompositionPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
