<template>
    <div class="poscredit-widget">
        <button class="poscredit-btn" @click.prevent="issueApplicationPosCreditOpen()">Оформить кредит</button>
    </div>
</template>

<script>
import { ref } from "vue";

const loadPoscreditScript = () => {
    if (!document.querySelector('script[src="https://api.b2pos.ru/shop/v4/connect.js"]')) {
        var service = document.createElement('script');
        service.src = 'https://api.b2pos.ru/shop/v4/connect.js';
        service.type = 'text/javascript';
        service.charset = 'UTF-8';
        document.documentElement.appendChild(service);
    }
}

if (document.readyState !== 'loading') {
    loadPoscreditScript();
} else {
    document.addEventListener('DOMContentLoaded', loadPoscreditScript);
}

export default {
    name: 'widget_composition',
    props: {
        productsList: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const accessID = process.env.VUE_APP_POSCREDIT_ACCESS_ID;
        const tradeID = process.env.VUE_APP_POSCREDIT_TRADE_ID;
        const productsList = ref(props.productsList);

        const issueApplicationPosCreditOpen = function () {
            window.poscreditServices('creditProcess', accessID,
                {
                    order: 'poscredit-order',
                    tradeID: tradeID,
                    products: productsList.value,
                    creditTermFrom: 6,
                    creditTermTo: 36,
                    installmentTermFrom: 6,
                    installmentTermTo: 6
                },
                function (result) {
                    if (result.success === false) {
                        alert('Произошла ошибка при попытке оформить кредит. Попробуйте позднее...');
                    }
                });
        }

        return {
            accessID,
            tradeID,
            productsList,
            issueApplicationPosCreditOpen
        }

    }
}
</script>