<template>
  <div class="home">
    <logo />
    <center>
      <h1>Пример вызова виджета</h1>
      <br>
      <widget-options :products-list="poscreditProducts" />
    </center>
  </div>
</template>

<script>
import Logo from "@/components/logo.vue";
import WidgetOptions from "@/components/WidgetOptions.vue";

export default {
  name: 'Index',
  data() {
    return {
      poscreditProducts: [
        {
          id: '001',
          name: 'Apple iPhone 15 128Гб',
          category: 'СМАРТФОН APPLE',
          price: 150000,
          count: 1
        }
      ]
    }
  },
  components: {
    Logo,
    WidgetOptions
  },
}
</script>
