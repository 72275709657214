<template>
    <div class="poscredit-widget">
        <button class="poscredit-btn" @click.prevent="issueApplicationPosCreditOpen()">Оформить кредит</button>
    </div>
</template>

<script>

function poscreditCheckStatus(result) {
     if(result === 1) console.log('Клиент ушел не оформив заявку до конца');
     if(result === 2) console.log('Клиенту отказали в кредите предложенные банки');
     if(result === 3) console.log('Клиент сам отказался от кредита после получения решения');
     if(result === 4) console.log('Заявка ушла в обработку кредитным инспекторам или перешла на ручной ввод');
     if(result === 5) console.log('Получено одобрение по заявке, но клиент не подтвердил выбор банка');
     if(result === 6) console.log('Клиент завершил оформление заявки и подтвердил выбор банка');
}

function poscreditSaveProfile(id) {
     //Полученный id заявки можно сохранить в БД, один из вариантов — отправкой $.post на URL-обработчик
     console.log("Номер заявки: "+id);
}


export default {
    name: 'widget_options',
    data() {
        return {
            accessID: process.env.VUE_APP_POSCREDIT_ACCESS_ID,
            tradeID: process.env.VUE_APP_POSCREDIT_TRADE_ID,
        }
    },
    props: {
        productsList: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        loadPoscreditScript() {
            if (!document.querySelector('script[src="https://api.b2pos.ru/shop/v4/connect.js"]')) {
                var service = document.createElement('script');
                service.src = 'https://api.b2pos.ru/shop/v4/connect.js';
                service.type = 'text/javascript';
                service.charset = 'UTF-8';
                document.documentElement.appendChild(service);
            }
        },
        issueApplicationPosCreditOpen() {

            window.poscreditServices('creditProcess', this.accessID,
                {
                    order: 'poscredit-order',
                    tradeID: this.tradeID,
                    products: this.productsList,
                    creditTermFrom: 6,
                    creditTermTo: 36,
                    installmentTermFrom: 6,
                    installmentTermTo: 6
                },
                function (result) {
                    if (result.success === false) {
                        alert('Произошла ошибка при попытке оформить кредит. Попробуйте позднее...');
                    }
                });
        }
    },
    mounted() {
        this.loadPoscreditScript();
    }
}
</script>