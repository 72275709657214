<template>
    <div class="home">
      <logo />
      <center>
        <h1>Пример вызова виджета</h1>
        <br>
        <widget-composition :products-list="poscreditProducts" />
      </center>
    </div>
  </template>
  
  <script>
  import Logo from "@/components/logo.vue";
  import WidgetComposition from "@/components/WidgetComposition.vue";
  
  export default {
    name: 'Index',
    data() {
    return {
      poscreditProducts: [
        {
          id: '001',
          name: 'Apple iPhone 15 128Гб',
          category: 'СМАРТФОН APPLE',
          price: 150000,
          count: 1
        }
      ]
    }
  },
    components: {
      Logo,
      WidgetComposition
    },
  }
  </script>
  